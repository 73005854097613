import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useRouteMatch} from "react-router-dom";
// import {Link} from "react-router-dom";
import normalize from 'json-api-normalizer';
import build from 'redux-object';

// Hooks
import useApi from '../Hooks/useApiGlobal';
import {updateModalsOpen, updateGlobalStorePage} from "../Store/action";

// Libraries
import Select from "react-select";

// Components
import {Loader} from "../Components/loader";
import Metatags from '../Layout/metas-tags/meta-tags';
import WysiwygSimple from "../Components/wysiwygSimple";
import SliderLarge from "../Components/sliderLarge";
import ListProducts from "../Components/listProducts";
import CarouselArticles from "../Components/carouselArticles";
import SwitchTab from "../Components/switchTab";
import Chatbot from "../Components/chatbot";

// Modals
import EconomyModal from '../Components/modals/economy-modal';
import BannerBrand from "../Components/bannerBrand";

const TemplateEconomyWebcoupon = ({_uid, typeEco, brand = "", shelf = "", univers = ""}) => {
    let dispatch = useDispatch();
    let {uuid, dateInscription} = useSelector(state => state.user);
    let diff = "";
    
    if(dateInscription) {
        diff = Math.floor(((new Date().getTime() / 1000) - dateInscription) / (60 * 60 * 24))
    }

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    // Page classname
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-economy'));
    }, [dispatch]); // isLoaded

   
    // Breakpoint
    const {desktop} = useSelector(state => state.breakpoint);

    const [seoMetaTitle, setSeoMetaTitle] = useState();//Type d'économie
    const [activeTab, setActiveTab] = useState(typeEco);//Type d'économie

    const [defaultShelf, setdefaultShelf] = useState({label: "Tous les rayons", value: "", remoteId: ""});
    const [defaultBrand, setdefaultBrand] = useState({label: "Toutes les marques", value: "", remoteId: ""});
    const [defaultUnivers, setdefaultUnivers] = useState({label: "Tous les univers", value: "", remoteId: ""});
    const [defaultSort, setDefaultSort] = useState(urlParams.get('welcome') ? {label: "Offre de Bienvenue", value: 1} : {label: "Trier par", value: 0});

    const [filtersApi, setFiltersApi] = useState('');

    // Infos de la page
    const [dataRedux, setDataRedux] = useState();
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/special',
        _uid: _uid,
        include: 'content_config_form_files',//,field_common_er_related_contents
        fields: '&fields[node--special]=metatag,title,field_special_configformref,content_config_form_files',
        method: 'get'
    });
    //Normalisation des infos de la page
    useEffect(() => {
        if (isLoaded && dataFetch) {
            const normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'nodeSpecial', _uid));
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line

    const [listFiltersUniverses, setListFiltersUniverses] = useState({});//Infos Listes déroulantes univers
    const [listFiltersShelves, setListFiltersShelves] = useState({});//Infos Listes déroulantes Shelf
    const [listFiltersBrands, setListFiltersBrands] = useState({});//Infos Listes déroulantes Brand

    //Requete qui recupere tous les filtres
    const [dataFetchFilter, isLoadedFilters] = useApi({ // eslint-disable-line
        name: 'api/lba-common/filter',
        method: 'get'
    });

    //Requete pour récup les cashback
    const [paramsCashback, setParamsCashback] = useState('api/lba-cashback/selection');
    const [currentSort, setCurrentSort] = useState(urlParams.get('welcome') ? 1 : 0);//Enregistre le trier par
    const [dataFetchCashback, isLoadedCashback] = useApi({
        name: paramsCashback,
        method: 'get',
        sort: 'welcome=' + currentSort,
        uuid: uuid
    });

    //Requete pour récup les webcoupon
    const [paramsWebcoupon, setParamsWebcoupon] = useState('api/lba-economy/selection');
    const [dataFetchWebcoupon, isLoadedWebcoupon] = useApi({
        name: paramsWebcoupon,
        method: 'get',
        sort: 'welcome=' + currentSort,
        uuid: uuid
    });

    const [currentFilters, setCurrentFilters] = useState({univers: '', shelf: '', brand: ''});//Enregistre les filtres avec clé courantes
    const [currentRemoteId, setCurrentRemoteId] = useState({univers: '', shelf: '', brand: ''});//Enregistre les filtres avec remoteId courants
    const [typeSearch, setTypeSearch] = useState("");//Type de recherche
    
    const findAliasInObject = (alias, object) => {
        for(var key in object){
            if(object[key].cashback_path_alias === alias || object[key].webcoupon_path_alias === alias){
                return key;
            }
        }

        return null;
    }

    //Check si on a un preremplissage à faire
    useEffect(() => {
        let allBrands = dataFetchFilter?.data?.brands;
        let allShelves = dataFetchFilter?.data?.shelves;
        let allUniverses = dataFetchFilter?.data?.universes;

        if(brand && allBrands){
            let drupalInternalTid = findAliasInObject(brand, allBrands);

            console.log("brand", drupalInternalTid)
            
            if(drupalInternalTid){
                let currentBrand = allBrands[drupalInternalTid];
                setCurrentFilters({univers: '', shelf: '', brand: drupalInternalTid});
                setCurrentRemoteId({univers: '', shelf: '', brand: currentBrand.remoteid});

                setdefaultBrand({
                    value: drupalInternalTid,
                    label: currentBrand.label,
                    remoteId: currentBrand.remoteid
                });
                setdefaultShelf({label: "Tous les rayons", value: "", remoteId: ""});
                setdefaultUnivers({label: "Tous les univers", value: "", remoteId: ""});
            }
        }
        
        else if(shelf && allShelves){
            let drupalInternalTid = findAliasInObject(shelf, allShelves);

            if(drupalInternalTid){
                let currentShelf = allShelves[drupalInternalTid];
                setCurrentFilters({univers: '', shelf: drupalInternalTid, brand: ''});
                setCurrentRemoteId({univers: '', shelf: currentShelf.remoteid, brand: ''});

                setdefaultBrand({label: "Toutes les marques", value: "", remoteId: ""});
                setdefaultShelf({
                    value: currentShelf,
                    label: currentShelf.label,
                    remoteId: currentShelf.remoteid
                });
                setdefaultUnivers({label: "Tous les univers", value: "", remoteId: ""});
            }
        }
        
        else if(univers && allUniverses){
            let drupalInternalTid = findAliasInObject(univers, allUniverses);
            
            console.log("univers", drupalInternalTid)

            if(drupalInternalTid){
                let currentUnivers = allUniverses[drupalInternalTid];
                setCurrentFilters({univers: drupalInternalTid, shelf: '', brand: ''});
                setCurrentRemoteId({univers: currentUnivers.remoteid, shelf: '', brand: ''});

                setdefaultBrand({label: "Toutes les marques", value: "", remoteId: ""});
                setdefaultShelf({label: "Tous les rayons", value: "", remoteId: ""});
                setdefaultUnivers({
                    value: currentUnivers,
                    label: currentUnivers.label,
                    remoteId: currentUnivers.remoteid
                });
            }
        }
    }, [dataFetchFilter, shelf, brand, univers]); // eslint-disable-line

    useEffect(() => {
        getUrlIntro();
    }, [typeSearch, activeTab]);

    //Requete pour recup les articles
    const [articlesRedux, setArticlesRedux] = useState()
    const [seasonality, setSeasonality] = useState(true);// Infos Articles
    const [showArticle, setShowArticle] = useState(false);
    const [paramsArticle, setParamsArticle] = useState({
            name: 'api/node/article',
            include: 'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_common_tr_universes,field_common_tr_shelves,field_common_tr_brands',
            fields: '&fields[node--article]=field_common_txt_title,field_common_pgph_ban,drupal_internal__nid,path,field_common_tr_universes,field_common_tr_shelves,field_common_tr_brands',
            filter: '&filter[field_common_list_seasonalities]=' + parseInt(new Date().getMonth() + 1),
            sort: '&sort[sort-created][path]=created&sort[sort-created][direction]=DESC',
            limit: 15,
            method: 'get'
        }
    );
    //Requete qui permet de recup les articles
    const [dataFetchArticles, isLoadedArticles] = useApi(paramsArticle);
    //Normalisation des articles
    useEffect(() => {
        if (isLoadedArticles && dataFetchArticles) {
            if (dataFetchArticles.data && dataFetchArticles.data.data && dataFetchArticles.data.data.length > 0) {
                const normalizeJsonArticles = normalize(dataFetchArticles.data);//Normalisation du json
                setArticlesRedux(build(normalizeJsonArticles, 'nodeArticle'));
            }
            else if (!showArticle) {
                setSeasonality(false);
                setParamsArticle({...paramsArticle, filter: filtersApi});
                setShowArticle(true)
            }
            else {
                const normalizeJsonArticles = normalize(dataFetchArticles.data);//Normalisation du json
                setArticlesRedux(build(normalizeJsonArticles, 'nodeArticle'));
            }
        }
    }, [isLoadedArticles, dataFetchArticles]); // eslint-disable-line

    //Requete pour textes SEO
    const [seoRedux, setSeoRedux] = useState();
    const [paramsSeoApi, setParamsSeoApi] = useState({});
    const [dataFetchSeo, isLoadedSeo] = useApi(paramsSeoApi);

    const [seoContent, setSeoContent] = useState({title: '', text: ''});
    //normalisation texte SEO
    useEffect(() => {
        if (isLoadedSeo && dataFetchSeo) {
            if (dataFetchSeo.data.data && dataFetchSeo.data.data.length > 0) {
                const normalizeSeoJson = normalize(dataFetchSeo.data);//Normalisation du json
                setSeoRedux(build(normalizeSeoJson, 'nodeEconomyIntro'));

                dataFetchSeo.data.data[0].attributes.metatag && dataFetchSeo.data.data[0].attributes.metatag.map(({tag, attributes}) => {
                    switch (tag) {
                        case "title" :
                            setSeoMetaTitle(attributes.content);
                            break;
                        default :
                            break;
                    }
                    return null;
                });
            }
            else {
                setSeoContent({title: '', text: ''});
            }
        }
    }, [isLoadedSeo, dataFetchSeo]);

    useEffect(() => {
        if (seoRedux) {
            if (currentFilters.brand !== "" || currentFilters.shelf !== "" || currentFilters.univers !== "") {
                setSeoContent({title: seoRedux[0].title, text: seoRedux[0].fieldArticleTxtIntro});
            }
        }
    }, [seoRedux]);// eslint-disable-line

    useEffect(() => {
        // console.log(filtersApi)
        if (seasonality) {
            setParamsArticle({
                ...paramsArticle,
                filter: filtersApi + '&filter[field_common_list_seasonalities]=' + parseInt(new Date().getMonth() + 1)
            })
        }
        else {
            setParamsArticle({...paramsArticle, filter: filtersApi})
        }
        setShowArticle(false)
    }, [filtersApi]); // eslint-disable-line

    const updateFilter = (list, type) => {
        if (list) {
            let options = [];
            Object.keys(list).map((key) => {
                options.push({label: list[key].label, value: key, remoteId: list[key].remoteid});
                return null;
            })

            // Sort descendant alphanumerical strings
            options.sort(function(a, b) {
                return a.label.localeCompare(b.label, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                });
            });

            options.unshift({label: type, value: "", remoteId: ""});
            return options;
        }
    };

    // Changement de filtre
    const handleFilter = (e, type) => {
        window.STAYHERE = true;

        setTimeout(() => {
            window.STAYHERE = false;
        }, 1500);

        if (!(e.value === '' && currentFilters.brand === '' && currentFilters.shelf === '' && currentFilters.univers === '')) {
            switch (type) {
                case 'univers':
                    setCurrentFilters({...currentFilters, univers: e.value});
                    setCurrentRemoteId({...currentRemoteId, univers: e.remoteId});
                    setdefaultUnivers(e);
                    break;
                case 'shelf':
                    setCurrentFilters({...currentFilters, shelf: e.value});
                    setCurrentRemoteId({...currentRemoteId, shelf: e.remoteId});
                    setdefaultShelf(e);
                    break;
                case 'brand':
                    setCurrentFilters({...currentFilters, brand: e.value});
                    setCurrentRemoteId({...currentRemoteId, brand: e.remoteId});
                    setdefaultBrand(e);
                    break;
                case 'sort':
                    // console.log(e.value);
                    setCurrentSort(e.value);
                    setShowLoader(true);
                    setDefaultSort(e)
                    const urlCurrent = new URLSearchParams(document.location.search);
                    if(e.value && !urlCurrent.get('welcome')) {
                        urlCurrent.append("welcome", "1");
                    }
                    else if(urlCurrent.get('welcome')) {
                        urlCurrent.delete("welcome");
                    }
                    window.history.replaceState('', '', document.location.pathname+'?'+urlCurrent.toString());
                    break;
                default:
                    setCurrentFilters({univers: '', shelf: '', brand: ''});
                    setCurrentRemoteId({univers: '', shelf: '', brand: ''});
                    break;
            }
        }
    };
    // FIN Univers
    const findItemInObject = (object, id, tab) => {
        for(var key in object){
            if(key === id){
                return object[key][tab];
            }
        }

        return null;
    }

    const getUrlIntro = () => {
        if(typeSearch && dataFetchFilter) {
            let correctedTypeSearch = (typeSearch == "universe" ? "univers" : typeSearch);
            
            const translationType = {
                "brand" : "brands",
                "shelf" : "shelves",
                "univers" : "universes",
            }
            const translationTab = {
                "webcoupon" : "webcoupon_path_alias",
                "cashback" : "cashback_path_alias"
            }
            const trueSearch = translationType[correctedTypeSearch];
            const trueTab = translationTab[activeTab];

            if(trueSearch && trueTab && dataFetchFilter?.data?.[trueSearch]){
                const landing = findItemInObject(dataFetchFilter.data[trueSearch], currentFilters[correctedTypeSearch], trueTab );

                if(landing) {
                    changeUrl(landing, true)
                }
                else if(landing === undefined) {
                    defaultUrl(true);
                } else{
                    defaultUrl(currentFilters[correctedTypeSearch] === "");
                }

            } else {
                defaultUrl();
            }
        }
        else {
            defaultUrl();
        }
    }

    const defaultUrl = (force = false) => {
        if(activeTab === "cashback" ) {
            changeUrl('/economies/remboursement', force);
        }
        else {
            changeUrl('/economies/bon-reduction', force);
        }
    }

    const changeUrl = (slug, force) => {
        if(force){
            let hash = document.location.hash;
            window.history.replaceState('', '', slug + document.location.search + (hash ? hash : ""));
        }
    }

    useEffect(() => {
        if (dataFetchFilter && dataFetchFilter.data) {
            if (currentFilters.brand !== "") {
                if (paramsCashback !== 'api/lba-cashback/filter/?filter=brand&filter_value[]=' + currentRemoteId.brand) { //la requete est differente par rapport à la premiere
                    setShowLoader(true);//affichage du loader
                    setParamsCashback('api/lba-cashback/filter/?filter=brand&filter_value[]=' + currentRemoteId.brand);//Requetes cashback uniquement sur les marques
                    setParamsWebcoupon('api/lba-economy/selection/brand/' + currentRemoteId.brand);//Requetes br uniquement sur les marques
                    setFiltersApi('&filter[field_common_tr_brands.field_api_remoteid_brandid]=' + currentRemoteId.brand);//Filtre des actus sur la marque
                    setParamsSeoApi({
                        name: 'api/node/economy_intro',
                        method: 'get',
                        include: 'field_common_tr_brand,field_common_tr_shelf,field_common_tr_universe',
                        filter: '&filter[field_economy_list_tab]=' + typeEco + '&filter[field_common_tr_brand.field_api_remoteid_brandid]=' + currentRemoteId.brand,
                        limit: 250
                    })
                }
                setListFiltersBrands(updateFilter(dataFetchFilter.data.brands, "Toutes les marques"));
                if (dataFetchFilter.data.brands[currentFilters.brand]) {
                    setListFiltersShelves(updateFilter(dataFetchFilter.data.brands[currentFilters.brand].shelves, "Tous les rayons"));//maj des rayons
                    setListFiltersUniverses(updateFilter(dataFetchFilter.data.brands[currentFilters.brand].universes, "Tous les univers"));//maj des univers
                }
                setTypeSearch("brand");
            }
            else if (currentFilters.shelf !== "") {
                if (paramsCashback !== 'api/lba-cashback/filter/?filter=shelf&filter_value[]=' + currentRemoteId.shelf) {//la requete est differente par rapport à la premiere
                    setShowLoader(true);//affichage du loader
                    setParamsCashback('api/lba-cashback/filter/?filter=shelf&filter_value[]=' + currentRemoteId.shelf);//Requetes cashback uniquement sur les rayons
                    setParamsWebcoupon('api/lba-economy/selection/shelf/' + currentRemoteId.shelf);//Requetes br uniquement sur les rayons
                    setFiltersApi('&filter[field_common_tr_shelves.field_api_remoteid_shelfid]=' + currentRemoteId.shelf);//Filtre des actus sur le rayon
                    setParamsSeoApi({
                        name: 'api/node/economy_intro',
                        method: 'get',
                        include: 'field_common_tr_brand,field_common_tr_shelf,field_common_tr_universe',
                        filter: '&filter[field_economy_list_tab]=' + typeEco + '&filter[field_common_tr_shelf.field_api_remoteid_shelfid]=' + currentRemoteId.shelf,
                        limit: 250
                    })
                }
                if (dataFetchFilter.data.shelves[currentFilters.shelf])
                    setListFiltersUniverses(updateFilter(dataFetchFilter.data.shelves[currentFilters.shelf].universes, "Tous les univers"));//Maj des univers

                if (currentFilters.univers === "") {
                    setListFiltersShelves(updateFilter(dataFetchFilter.data.shelves, "Tous les rayons"));//maj des univers
                }
                else {
                    if (dataFetchFilter.data.universes[currentFilters.univers])
                        setListFiltersShelves(updateFilter(dataFetchFilter.data.universes[currentFilters.univers].shelves, "Tous les rayons"));//maj des rayons
                }

                if (currentFilters.brand === "") { //S'il n'y a pas de marque de rempli ==> On le remplis
                    if (dataFetchFilter.data.shelves[currentFilters.shelf])
                        setListFiltersBrands(updateFilter(dataFetchFilter.data.shelves[currentFilters.shelf].brands, "Toutes les marques"));//maj des marques
                }
                setTypeSearch("shelf");
            }
            else if (currentFilters.univers !== "") { // uniquement univers de rempli
                if (paramsCashback !== 'api/lba-cashback/filter/?filter=universe&filter_value[]=' + currentRemoteId.univers) {
                    setShowLoader(true);//affichage du loader
                    setParamsCashback('api/lba-cashback/filter/?filter=universe&filter_value[]=' + currentRemoteId.univers);//Requetes cashback uniquement sur l'univers'
                    setParamsWebcoupon('api/lba-economy/selection/universe/' + currentRemoteId.univers);//Requetes br uniquement sur l'univers'
                    setFiltersApi('&filter[field_common_tr_universes.field_api_remoteid_universeid]=' + currentRemoteId.univers);//Filtre des actus sur l'univers
                    setParamsSeoApi({
                        name: 'api/node/economy_intro',
                        method: 'get',
                        include: 'field_common_tr_brand,field_common_tr_shelf,field_common_tr_universe',
                        filter: '&filter[field_economy_list_tab]=' + typeEco + '&filter[field_common_tr_universe.field_api_remoteid_universeid]=' + currentRemoteId.univers,
                        limit: 250
                    })
                }

                if (currentFilters.shelf === "") { //S'il n'y a pas de raton de rempli ==> On le remplis
                    if (dataFetchFilter.data.universes[currentFilters.univers])
                        setListFiltersShelves(updateFilter(dataFetchFilter.data.universes[currentFilters.univers].shelves, "Tous les rayons"));//maj des rayons
                }
                if (currentFilters.brand === "") { //S'il n'y a pas de marque de rempli ==> On le remplis
                    if (dataFetchFilter.data.universes[currentFilters.univers])
                        setListFiltersBrands(updateFilter(dataFetchFilter.data.universes[currentFilters.univers].brands, "Toutes les marques"));//maj des marques
                }
                if (currentFilters.shelf === "" && currentFilters.brand === "") {
                    setListFiltersUniverses(updateFilter(dataFetchFilter.data.universes, "Tous les univers"));//Maj des univers
                }
                setTypeSearch("universe");
            }
            else {
                if (paramsCashback !== 'api/lba-cashback/selection') {
                    setShowLoader(true);
                    setParamsCashback('api/lba-cashback/selection');
                    setParamsWebcoupon('api/lba-economy/selection');
                    setFiltersApi('');
                }
                setListFiltersUniverses(updateFilter(dataFetchFilter.data.universes, "Tous les univers"));//maj des univers
                setListFiltersShelves(updateFilter(dataFetchFilter.data.shelves, "Tous les rayons"));//maj des rayons
                setListFiltersBrands(updateFilter(dataFetchFilter.data.brands, "Toutes les marques"));//maj des marques
                if (dataRedux) {
                    // console.log(typeEco)
                    if (typeEco === "webcoupon") {
                        setSeoContent(dataRedux.fieldSpecialConfigformref.data.introductions.webcoupon);
                    }
                    else if (typeEco === "cashback") {
                        setSeoContent(dataRedux.fieldSpecialConfigformref.data.introductions.cashback);
                    }
                }
                setTypeSearch("");
            }
            getUrlIntro();
        }
    }, [currentFilters, dataFetchFilter, uuid]); // eslint-disable-line

    const [showLoader, setShowLoader] = useState(true);// Affichage du loader cashback/webcoupons

    useEffect(() => {
        if (isLoadedCashback === true && isLoadedWebcoupon === true && dataFetchWebcoupon?.data && dataFetchCashback?.data) {
            setShowLoader(false);            
        }
    }, [isLoadedWebcoupon, isLoadedCashback, dataFetchWebcoupon?.data, dataFetchCashback?.data]);

    //-- SwitchTab
    let tabs = [
        {id: 1, title: "Me faire rembourser"},
        {id: 2, title: "Imprimer mes bons"}
    ];

    const [fullCarousel, setFullCarousel] = useState({cashback: [], webcoupon: []});
    useEffect(() => {
        let slider = [];
        if (dataRedux) {
            slider = dataRedux.fieldSpecialConfigformref.data.slider.common;
            setFullCarousel({
                cashback: slider.concat(dataRedux.fieldSpecialConfigformref.data.slider.cashback),
                webcoupon: slider.concat(dataRedux.fieldSpecialConfigformref.data.slider.webcoupon)
            });

            if (currentFilters.univers === "" && currentFilters.shelf === "" && currentFilters.brand === "") {
                if (activeTab === "cashback") {
                    if (dataRedux.fieldSpecialConfigformref.data.introductions.cashback) {
                        setSeoContent(dataRedux.fieldSpecialConfigformref.data.introductions.cashback)
                    }
                    else {
                        setSeoContent({title: '', text: ''})
                    }
                }
                else {
                    if (dataRedux.fieldSpecialConfigformref.data.introductions.webcoupon) {
                        setSeoContent(dataRedux.fieldSpecialConfigformref.data.introductions.webcoupon)
                    }
                    else {
                        setSeoContent({title: '', text: ''})
                    }
                }
            }
            else {
                if (currentFilters.brand !== "") {
                    setParamsSeoApi({
                        name: 'api/node/economy_intro',
                        method: 'get',
                        include: 'field_common_tr_brand,field_common_tr_shelf,field_common_tr_universe',
                        filter: '&filter[field_economy_list_tab]=' + activeTab + '&filter[field_common_tr_brand.field_api_remoteid_brandid]=' + currentRemoteId.brand,
                        limit: 250
                    })
                }
                else if (currentFilters.shelf !== "") {
                    setParamsSeoApi({
                        name: 'api/node/economy_intro',
                        method: 'get',
                        include: 'field_common_tr_brand,field_common_tr_shelf,field_common_tr_universe',
                        filter: '&filter[field_economy_list_tab]=' + activeTab + '&filter[field_common_tr_shelf.field_api_remoteid_shelfid]=' + currentRemoteId.shelf,
                        limit: 250
                    })
                }
                else if (currentFilters.univers !== "") {
                    setParamsSeoApi({
                        name: 'api/node/economy_intro',
                        method: 'get',
                        include: 'field_common_tr_brand,field_common_tr_shelf,field_common_tr_universe',
                        filter: '&filter[field_economy_list_tab]=' + activeTab + '&filter[field_common_tr_universe.field_api_remoteid_universeid]=' + currentRemoteId.univers,
                        limit: 250
                    })
                }
            }
        }
    }, [activeTab, dataRedux]); // eslint-disable-line

    useEffect(()=>{
        if(dataRedux){
            let indexCanonical = dataRedux.metatag.findIndex((tag)=>tag.attributes.rel ==="canonical")
            if(indexCanonical > -1){
                let url = new URL(window.location)
                let pathCanonical =  dataRedux.metatag[indexCanonical].attributes.href
                if((document.location.href) !== pathCanonical){
                    dataRedux.metatag[indexCanonical].attributes.href = window.location.href
                    setDataRedux({...dataRedux})
                }
            }
        }
    }, [window.location.href, dataRedux])

    const renderPage = () => {
            //-- Slider
            const {metatag, title, contentConfigFormFiles: fullCarouselImages, fieldSpecialConfigformref: {data: {popin: {cashback, webcoupon}}}} = dataRedux;

            const listFiltersSort = [{label: "Trier par", value: 0}, {label: "Offre de Bienvenue", value: 1}];

            // Page info for tracking page_view event
            let pageviewEvent = {
                category: 'Economies',
                category2: activeTab === "cashback" ? "Me faire rembourser" : "Imprimer mes bons",
                category3: '',
            };

            return (
                <>
                    {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent}
                                          seoTitle={seoMetaTitle}/>}
                    <main className="main">
                        {
                            (fullCarousel.cashback && fullCarousel.cashback.length > 0) &&
                            <div className={activeTab === "cashback" ? `c-sliderLarge_show` : `c-sliderLarge_hide`}>
                                <SliderLarge carousel={fullCarousel.cashback}
                                             images={fullCarouselImages} desktop={desktop} name="Cashback" isActiveTab={activeTab === "cashback" ? `true` : `false`} />
                            </div>
                        }

                        {
                            (fullCarousel.webcoupon && fullCarousel.webcoupon.length > 0) &&
                            <div className={activeTab === "webcoupon" ? `c-sliderLarge_show` : `c-sliderLarge_hide`}>
                                <SliderLarge carousel={fullCarousel.webcoupon}
                                             images={fullCarouselImages} desktop={desktop} name="webcoupon" isActiveTab={activeTab === "webcoupon" ? `true` : `false`} />
                            </div>
                        }


                        {(seoContent && (seoContent.title !== '' || seoContent.text)) &&
                        <div className="container" id="printContainer">
                            <div className="t-article_container grey--">
                                <WysiwygSimple title={seoContent.title && seoContent.title}
                                               content={seoContent.text && seoContent.text.processed}/>
                            </div>
                        </div>
                        }
                        <SwitchTab needScroll={true} anchorId={"anchor-offer"} tabs={tabs} activeTab={activeTab === "cashback" ? 1 : 2} setActiveTab={setActiveTab}/>

                        {(listFiltersUniverses && listFiltersShelves && listFiltersBrands) &&
                        <div className="container">
                            <div className="c-filters flex--">
                                <div className="c-filtersLeft">
                                    {(listFiltersUniverses && listFiltersUniverses.length > 0) &&
                                    <Select options={listFiltersUniverses}
                                            className="c-filter_select"
                                            classNamePrefix="c-filter_select"
                                            onChange={(e) => handleFilter(e, 'univers')}
                                            value={defaultUnivers}
                                        // menuIsOpen="true"
                                            isDisabled={showLoader}
                                    />
                                    }
                                    {(listFiltersShelves && listFiltersShelves.length > 0) &&
                                    <Select options={listFiltersShelves}
                                            className="c-filter_select"
                                            classNamePrefix="c-filter_select"
                                            onChange={(e) => handleFilter(e, 'shelf')}
                                            value={defaultShelf}
                                            isDisabled={showLoader}
                                        // menuIsOpen="true"
                                    />
                                    }
                                    {(listFiltersBrands && listFiltersBrands.length > 0) &&
                                    <Select options={listFiltersBrands}
                                            className="c-filter_select"
                                            classNamePrefix="c-filter_select"
                                            onChange={(e) => handleFilter(e, 'brand')}
                                            value={defaultBrand}
                                            isDisabled={showLoader}
                                        // menuIsOpen="true"
                                    />
                                    }
                                </div>
                                {(uuid && diff <=30) &&
                                    <div className="c-filtersRight">
                                        <Select options={listFiltersSort} placeholder="Trier par"
                                                className="c-filter_select"
                                                classNamePrefix="c-filter_select"
                                                onChange={(e) => handleFilter(e, 'sort')}
                                                value={defaultSort}
                                                isDisabled={showLoader}
                                            // menuIsOpen="true"
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        }

                        {/* <ListProducts
                            products={activeTab === "cashback" ? dataFetchCashback?.data : dataFetchWebcoupon?.data}
                            button={{title: "Voir toutes les réductions", url: "#"}}
                            type={activeTab === "cashback" ? "refund" : "print"}
                            name={title} /> */}

                        {(dataFetchCashback && dataFetchCashback.data && dataFetchWebcoupon && dataFetchWebcoupon.data && showLoader === false /*&& showLoader2 === false*/) ?
                            <ListProducts
                                products={activeTab === "cashback" ? dataFetchCashback.data : dataFetchWebcoupon.data}
                                button={{title: "Voir toutes les réductions", url: "#"}}
                                type={activeTab === "cashback" ? "refund" : "print"}
                                needLoader={true}
                                name={title} />
                            :
                            <Loader/>
                        }

                        <BannerBrand />


                        {(articlesRedux && articlesRedux.length > 0) &&
                        <div className="c-listArticles">
                            <div className="container">
                                <h2>Cela pourrait vous plaire</h2>
                                <CarouselArticles carousel={articlesRedux} desktop={desktop}
                                                  carouselName="more-c-articles"/>
                            </div>
                        </div>
                        }

                        <EconomyModal
                            params={activeTab === "cashback" ? cashback : webcoupon}
                            type={activeTab}/>
                    </main>

                    <Chatbot/>
                </>
            )
        }
    ;
    return (isLoaded && dataRedux) ? renderPage() : <Loader/>
};

export default TemplateEconomyWebcoupon;