import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

// Hooks
import { updateGlobalStorePage } from '../Store/action';
import useApi from '../Hooks/useApiGlobal';

// Components
import { Loader } from "../Components/loader";
import Metatags from '../Layout/metas-tags/meta-tags';
// import Outils from "../Components/article/outils";
import VisuelLarge from "../Components/visuelLarge";
import Video from "../Components/article/video";
import WysiwygSimple from "../Components/wysiwygSimple";
import Tableau from '../Components/tableau';
import Cta from "../Components/cta";
import VisuelLegend from "../Components/visuelLegend";
import Steps from "../Components/article/stepText";
import CarouselArticles from "../Components/carouselArticles";
import Comments from "../Components/comments";
import CarouselCashback from "../Components/carouselCashback";
import CarouselWebcoupon from "../Components/carouselWebcoupon";
// import BannerBrand from "../Components/bannerBrand";
// import ContactTwitter from '../Components/contactTwitter';
// import Newsletter from '../Components/newsletter';

const TemplateLandingPage = ({ _uid }) => {

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-landingPage'));
    }, []); // eslint-disable-line

    const { desktop } = useSelector(state => state.breakpoint);

    // Get global info
    // let dataGlobal = useSelector(state => state.global).global;

    //Recuperation des infos de la page
    const [dataRedux, setDataRedux] = useState();
    // const [universRedux, setUniversRedux] = useState();  // eslint-disable-line
    // const [brandsRedux, setBrandsRedux] = useState();  // eslint-disable-line
    const [articlesRedux, setArticlesRedux] = useState('');// Infos Articles
    const [apiArticles, setApiArticles] = useState({});// Infos Articles

    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/landing_page',
        _uid: _uid,
        include: 'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_brand_pgph_contents,field_brand_pgph_contents.field_common_pgph_ban.field_respimg_img_mobile,field_brand_pgph_contents.field_common_pgph_ban.field_respimg_img_desktop,field_brand_pgph_contents.field_landing_video,field_brand_pgph_contents.paragraph_type,field_common_tr_universe,field_common_tr_brands,field_brand_pgph_contents.field_landing_pgph_steps,field_brand_pgph_contents.field_landing_pgph_steps.field_diy_img_step',
        fields: '&fields[taxonomy_term--universe]=name,field_api_remoteid_universeid&fields[taxonomy_term--brand]=field_api_remoteid_brandid&fields[paragraph–economy_webcoupons]=field_economy_txt_ean_codes&fields[paragraph-bons_de_reduction]=field_economy_txt_offerrefs',
        method: "get"
    });

    useEffect(() => {
        if (dataFetch && dataFetch.data) {
            const normalizeJson = normalize(dataFetch.data);
            setDataRedux(build(normalizeJson, 'nodeLandingPage', _uid));
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line


    //Requete qui permet de recup les articles
    const [dataFetchArticles, isLoadedArticles] = useApi(apiArticles);

    useEffect(() => {
        if (isLoadedArticles && dataFetchArticles) {
            let normalizeJsonArticles = normalize(dataFetchArticles.data);//Normalisation du json
            setArticlesRedux(build(normalizeJsonArticles, 'nodeArticle'));
        }
    }, [isLoadedArticles, dataFetchArticles]); // eslint-disable-line

    useEffect(() => {
        if (dataRedux) {
            // if (dataRedux.fieldCommonTrUniverse && dataRedux.fieldCommonTrUniverse.length > 0) {
            //     setUniversRedux(dataRedux.fieldCommonTrUniverse[0].name);
            // }
            if (dataRedux.fieldLandingBoolShowArticles) {
                if (dataRedux.fieldCommonTrUniverse && dataRedux.fieldCommonTrUniverse.length > 0) {
                    setApiArticles({
                        name: 'api/node/article',
                        include: 'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_common_tr_universes',
                        fields: '&fields[node--article]=field_common_txt_title,field_common_pgph_ban,drupal_internal__nid,path,field_common_tr_universes',
                        filter: '&filter[field_common_tr_universes.id]=' + dataRedux.fieldCommonTrUniverse[0].id,
                        sort: '&sort[sort-created][path]=created&sort[sort-created][direction]=DESC',
                        limit: 15,
                        method: 'get'
                    });
                } else {
                    setApiArticles({
                        name: 'api/node/article',
                        include: 'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_common_tr_universes',
                        fields: '&fields[node--article]=field_common_txt_title,field_common_pgph_ban,drupal_internal__nid,path,field_common_tr_universes',
                        sort: '&sort[sort-created][path]=created&sort[sort-created][direction]=DESC',
                        limit: 15,
                        method: 'get'
                    });
                }
            }
        }
    }, [dataRedux]);

    const renderPage = () => {
        const { drupalInternalNid, fieldCommonPgphBan: headerImg, metatag, fieldBrandPgphContents: flexibles, fieldCommonTrUniverse, fieldCommonTrBrands } = dataRedux;


        let brandIDs;
        if (fieldCommonTrBrands && fieldCommonTrBrands.length > 0) {
            let brandsArray = [];
            fieldCommonTrBrands.map(universe => {
                brandsArray.push(universe.fieldApiRemoteidBrandid);
                return null;
            });
            brandIDs = brandsArray;
        }
        let universeIDs;
        if (fieldCommonTrUniverse && fieldCommonTrUniverse.length > 0) {
            let universesArray = [];
            fieldCommonTrUniverse.map(universe => {
                universesArray.push(universe.fieldApiRemoteidUniverseid);
                return null;
            });
            universeIDs = universesArray;
        }

        return (
            <>
                <Metatags listMeta={metatag} />
                {headerImg &&
                <VisuelLarge urlVisuel={headerImg} desktop={desktop} isTrueSize={true} />
                }
                <div className="container" id="printContainer">
                    {
                        flexibles[0]?.paragraphType?.label !== "Image responsive avec légende" && <div className="t-landingPage_container rounded--">
                            &nbsp;
                        </div>
                    }
                    
                    {(flexibles && flexibles.length > 0) &&
                        flexibles.map(({ paragraphType: { label }, fieldCommonTitle, fieldCommonTxtTextbody, fieldEconomyTxtEanCodes, fieldEconomyTxtOfferrefs, fieldCtaLanding: cta, fieldLandingVideo: video, fieldCommonPgphBan: img, fieldCommonTxtTexttitle: legend, fieldLandingPgphSteps: steps }, index) => {

                            return (
                                <React.Fragment key={index}>
                                    {
                                        {
                                            'Texte': (fieldCommonTitle || fieldCommonTxtTextbody) &&
                                                <div className="t-landingPage_container">
                                                    <WysiwygSimple title={fieldCommonTitle && fieldCommonTitle.processed}
                                                        content={fieldCommonTxtTextbody && fieldCommonTxtTextbody.processed} transform={false} />
                                                </div>,
                                            'Tableau': fieldCommonTxtTextbody &&
                                                <div className="t-landingPage_container">
                                                    <Tableau {...fieldCommonTxtTextbody} />
                                                </div>,
                                            'CTA ': (cta && cta.uri && cta.title) &&
                                                <div className="t-landingPage_container">
                                                    <Cta url={cta.uri.replace('internal:', '')}
                                                        target={cta.uri.includes("internal:/") ? "_self" : "_blank"}
                                                        label={cta.title} />
                                                </div>,
                                            'Bloc Cashback': <CarouselCashback desktop={desktop}
                                                universeID={universeIDs && universeIDs}
                                                brandID={brandIDs && brandIDs} 
                                                offerRefs={fieldEconomyTxtOfferrefs && fieldEconomyTxtOfferrefs}/>,
                                            'Bloc Webcoupons': <CarouselWebcoupon desktop={desktop}
                                                universeID={universeIDs && universeIDs}
                                                brandID={brandIDs && brandIDs} 
                                                eanCodes={fieldEconomyTxtEanCodes && fieldEconomyTxtEanCodes}/>,
                                            'Vidéo': (video && video.filename) &&
                                                <div className="t-landingPage_container">
                                                    <Video infoVideo={video}
                                                        infoImage={img} desktop={desktop} />
                                                </div>,
                                            'Image responsive avec légende': img &&
                                                <div className="t-landingPage_container">
                                                    <VisuelLegend visuel={img}
                                                        legend={legend && legend} desktop={desktop} />
                                                </div>,
                                            'Etapes': (steps && steps.length > 0) &&
                                                <div className="t-landingPage_container">
                                                    <Steps listStep={steps} desktop={desktop}
                                                        className="column--" />
                                                </div>
                                        }[label]
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </div>

                <div className="t-landingPage_grey">
                    <Comments _uid={_uid} dataType="node--landing_page" contentType="Landing page" />

                    {(articlesRedux && articlesRedux.length > 0) &&
                        <div className="container">
                            <div className="t-landingPage_articles">
                                <h2 className="section-title">Cela pourrait vous plaire</h2>
                                <CarouselArticles carousel={articlesRedux} desktop={desktop}
                                    carouselName="more-c-articles" />
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    };
    return (isLoaded && dataRedux) ? renderPage() : <Loader />
};

export default TemplateLandingPage;
