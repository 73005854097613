import React, {useEffect, useState} from "react";
// import useApi from '../Hooks/useApi';
// import Metatags from '../Layout/metas-tags/meta-tags';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {updateGlobalStorePage} from '../Store/action';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

//HOOKS
import useApi from '../Hooks/useApiGlobal';
import {getClientCredential} from '../Utils/getClientService';
import {getRefreshToken} from '../Utils/cookie';

import {camelize} from '../Utils/transformText';

// Components
import {Loader} from "../Components/loader";
import ListUniverse from "../Components/listUniverse"
import CarouselArticles from "../Components/carouselArticles";
import Metatags from '../Layout/metas-tags/meta-tags';
import CLink from '../Components/cLinks';
import BannerBrand from "../Components/bannerBrand";

// Utilis
import chatBoxManager from "../Utils/chatBoxManager";

// SwiperCore.use([Navigation, Pagination, Scrollbar]);

const TemplateTipsAndTricks = ({_uid}) => {
    let dispatch = useDispatch();
    const { chatbot } = useSelector(state => state.global).global;

    useEffect(() => {
        dispatch(updateGlobalStorePage('t-tips'));
    }, [dispatch]); // isLoaded

    // Breakpoint
    const {desktop} = useSelector(state => state.breakpoint);
    const location = useLocation();

    //Pour filtrer Recup univers et hub
    const [filterElt, setFilterElt] = useState({
        univers: '',
        hub: ''
    });

    const consumerId = getClientCredential(getRefreshToken, 'client_credentials');


    const [showLoader, setShowLoader] = useState(true);
    const [dataPage, setDataPage] = useState();
    const [dataFetchPage, isLoadedPage] = useApi({
        name: 'api/node/special',
        _uid: _uid,
        fields: '&fields[node--special]=metatag',
        method: 'get'
    });

    useEffect(() => {
        if (isLoadedPage && dataFetchPage) {
            const normalizeJson = normalize(dataFetchPage.data);//Normalisation du json

            let dataPage = build(normalizeJson, 'nodeSpecial', _uid)
            let indexCanonical = dataPage.metatag.findIndex((tag)=>tag.attributes.rel ==="canonical")
            if(indexCanonical > -1){
              let url = new URL(window.location)
              dataPage.metatag[indexCanonical].attributes.href = url.href
            }

            setDataPage(dataPage);
        }
    }, [isLoadedPage, dataFetchPage]); // eslint-disable-line

    useEffect(() => {
        if (dataPage) {
            let indexCanonical = dataPage.metatag.findIndex((tag)=>tag.attributes.rel ==="canonical")
            dataPage.metatag[indexCanonical].attributes.href = window.location
        }
    }, [filterElt]); // eslint-disable-line

    //Requete pour récup les infos de l'univers
    const [universRedux, setUniversRedux] = useState('');// Infos Articles
    const [dataFetchUnivers, isLoadedUnivers] = useApi({
        name: 'api/taxonomy_term/universe',
        fields: '&fields[taxonomy_term--universe]=name,field_api_remoteid_universeid,drupal_internal__tid,path,field_common_txt_slug',
        method: 'get'
    });

    useEffect(() => {
        if (isLoadedUnivers && dataFetchUnivers) {
            let normalizeJsonUnivers = normalize(dataFetchUnivers.data);//Normalisation du json
            setUniversRedux(build(normalizeJsonUnivers, 'taxonomyTermUniverse'));
        }
    }, [isLoadedUnivers, dataFetchUnivers]);//chargement requete ok


    //Requete pour récup les hub
    const [hubRedux, setHubRedux] = useState();//Infos Liste Hub
    const [dataFetchHub, isLoadedHub] = useApi({
        name: 'api/taxonomy_term/hub',
        // fields:'&fields[taxonomy_term--hub]=name,taxonomy_term--universe.name,taxonomy_term--universe.path,taxonomy_term--universe.field_api_remoteid_universeid,taxonomy_term--universe.drupal_internal__tid',
        include: 'field_api_tr_universe',
        method: 'get'
    });

    //Normalize les hub
    useEffect(() => {
        if (isLoadedHub && dataFetchHub) {
            const normalizeJsonHub = normalize(dataFetchHub.data);//Normalisation du json
            setHubRedux(build(normalizeJsonHub, 'taxonomyTermHub'));
        }
    }, [isLoadedHub, dataFetchHub]);//chargement requete ok

    const [decoupledApi, setDecoupledApi] = useState({})
    useEffect(() => {
        if (hubRedux && universRedux) {
            setDecoupledApi({
                name: 'router/translate-path',
                path: location.pathname,
                method: 'get',
            })
        }
    }, [hubRedux, universRedux]); // eslint-disable-line

    const [dataDecoupled, isLoadedDecoupled] = useApi(decoupledApi);

    useEffect(() => {
        if (dataDecoupled) {
            if (dataDecoupled.data && dataDecoupled.data.lba_articles) {
                const universeItem = universRedux.find(element => element.drupalInternalTid === filterElt.univers);

                setFilterElt({
                    univers: dataDecoupled.data.lba_articles.tipsAndTricksFilters.universe ? dataDecoupled.data.lba_articles.tipsAndTricksFilters.universe.tid : '',
                    slugUnivers : universeItem ? universeItem.fieldCommonTxtSlug : '',
                    hub: dataDecoupled.data.lba_articles.tipsAndTricksFilters.hub ? dataDecoupled.data.lba_articles.tipsAndTricksFilters.hub.tid : ''
                })
            }
        }
    }, [dataDecoupled, isLoadedDecoupled]);


    //Requete qui permet de recup les articles
    const [paramsApiArticle, setParamsApiArticle] = useState({
        name: 'api/lba-articles/tips',
        fields: '&fields[node--article_video]=field_common_txt_title,field_common_pgph_ban,field_common_duration,drupal_internal__nid,path,field_common_tr_universes,field_common_video&fields[node--article]=field_common_txt_title,field_common_pgph_ban,field_common_duration,drupal_internal__nid,path,field_common_tr_universes&fields[node--diy]=field_common_txt_title,field_common_pgph_ban,field_common_duration,drupal_internal__nid,path,field_common_tr_universes&fields[file--file]=status&fields[paragraph--responsive_image]=field_respimg_img_desktop,field_respimg_img_mobile&fields[taxonomy_term--universe]=name',
        include: 'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_common_tr_universes',
        method: 'get',
        consumerId: consumerId.client_id
        // limit: 15
    });
    const [dataFetchArticles, isLoadedArticles] = useApi(paramsApiArticle);

    const [listCPVP, setListCPVP] = useState({});
    const [listECM, setListECM] = useState({});
    const [listLPL, setListLPL] = useState({});
    useEffect(() => {

        if (isLoadedArticles && dataFetchArticles) {
            // console.log('dataFetchArticles', dataFetchArticles);
            const listCPVP = [];
            if (dataFetchArticles.data.celaPourraitVousPlaire && dataFetchArticles.data.celaPourraitVousPlaire.length > 0) {
                dataFetchArticles.data.celaPourraitVousPlaire.forEach(function (item, index) {
                    let contentType = camelize(item.data.type);
                    let normalizeJsonArticles = normalize(item);//Normalisation du json
                    listCPVP.push(build(normalizeJsonArticles, contentType)[0]);
                });
            }
            setListCPVP(listCPVP);

            const listECM = [];
            if (dataFetchArticles.data.enCeMoment && dataFetchArticles.data.enCeMoment.length > 0) {
                dataFetchArticles.data.enCeMoment.forEach(function (item, index) {
                    let contentType = camelize(item.data.type);
                    let normalizeJsonArticles = normalize(item);//Normalisation du json
                    listECM.push(build(normalizeJsonArticles, contentType)[0]);
                });
            }
            setListECM(listECM);

            const listLPL = [];
            if (dataFetchArticles.data.lesPlusLus && dataFetchArticles.data.lesPlusLus.length > 0) {
                dataFetchArticles.data.lesPlusLus.forEach(function (item, index) {
                    let contentType = camelize(item.data.type);
                    let normalizeJsonArticles = normalize(item);//Normalisation du json
                    listLPL.push(build(normalizeJsonArticles, contentType)[0]);
                });
            }
            setListLPL(listLPL);
            setShowLoader(false)
            // console.log(dataFetchArticles.data.celaPourraitVousPlaire);
        }
    }, [isLoadedArticles]); // eslint-disable-line


    useEffect(() => {
        if (filterElt) {
            chatBoxManager.reset()
            if(hubRedux){
                const hub = hubRedux.find(element => element.drupalInternalTid === filterElt.hub);
                if(hub){
                    let globalTags = chatbot.hub_terms.map(hub => hub.uuid)
                    chatBoxManager.check([hub], globalTags);
                }
            }
            setShowLoader(true);
            if (filterElt.hub && filterElt.hub !== '') {
                setParamsApiArticle({...paramsApiArticle, name: 'api/lba-articles/tips/hub/' + filterElt.hub})
            }
            else if (filterElt.univers && filterElt.univers !== '') {
                setParamsApiArticle({...paramsApiArticle, name: 'api/lba-articles/tips/universe/' + filterElt.univers})
            }
            else {
                setParamsApiArticle({...paramsApiArticle, name: 'api/lba-articles/tips'})
            }
        }

    }, [filterElt]); // eslint-disable-line

    const renderPage = () => {
        
        const {metatag} = dataPage;

        let pageviewEvent = {
            category: 'Conseils et Astuces',
            category2: '',
            category3: ''
        };

        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent}/>}
                <main className="main">

                    {(universRedux && hubRedux) &&
                    <div className="container">
                        <h2 className="section-title">Univers</h2>
                        <ListUniverse universList={universRedux} desktop={desktop} subuniverses={hubRedux}
                                      filterElt={filterElt} setFilterElt={setFilterElt} showLoader={showLoader} url='/conseils-astuces/' />
                    </div>
                    }


                    {listLPL &&
                    <List list={listLPL} listName="most-read" title="Les plus lus" showLoader={showLoader}
                          desktop={desktop}/>
                    }


                    <BannerBrand />


                    {listCPVP &&
                    <List list={listCPVP} listName="celaPourraitVousPlaire" title="Cela pourrait vous plaire"
                          showLoader={showLoader} desktop={desktop}/>
                    }


                    {listECM &&
                    <List list={listECM} listName="moment" title="En ce moment" showLoader={showLoader}
                          desktop={desktop}/>
                    }

                    <div className="c-pagination">
                        <CLink url="/tous-les-conseils-astuces" title="Voir tous les conseils et astuces" className="c-button btn-2" />
                    </div>
                </main>

            </>
        )
    };
    // return (isLoadedPage && dataPage && isLoadedUnivers && isLoadedHub) ? renderPage() : <Loader/>
    return (isLoadedPage && dataPage) ? renderPage() : <Loader/>
};

const List = ({showLoader, list, listName, title, desktop}) => {
    return (
        <div className="container">
            <h2 className="section-title">{title}</h2>
            {
                showLoader ?
                    <Loader/>
                    :
                    list.length > 0 ?
                        <CarouselArticles carousel={list} desktop={desktop}
                                          carouselName={listName}/>
                        :
                        <p className="c-carouselArticles-noresults">Aucun article ne correspond à la recherche</p>
            }
        </div>
    )
};

export default TemplateTipsAndTricks;