import './Styles/App.scss';
import React, { useState, useEffect } from 'react';
import ScrollToTop from './Components/ScrollToTop';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import RouteForTemplates from './Layout/routes/route-for-templates';
import history from "./Utils/history";

// HOOKS
import useApi from './Hooks/useApi';
import useApiGlobal from "./Hooks/useApiGlobal";
import { useSelector, useDispatch } from 'react-redux';
import {
    updateGlobalStoreGlobal,
    updateGlobalisLoaded,
    updateGlobalStoreRoutes,
    updateGlobalScrollLock,
    updateGlobalStoreLogFromPage,
    updateIsMobile,
    updateIsDesktopOnly,
    updateIsDesktop,
    updateGlobalYoutube,
    updateRoutesUpdate,
    updateCartCashback,
    updateCartWebcoupon,
    updateCartCashbackError,
    updateCartWebcouponError,
    updateUserFavorites
} from './Store/action';

// TPL PARTS
import Header from './Layout/header/header';
import Breadcrumb from './Layout/header/breadcrumb';
import Search from './Layout/header/search';
import Footer from './Layout/footer/footer';

import { getToken, setToken, setCookie, setCookieSeconds, getCookie, eraseCookie } from './Utils/cookie';

// Modals
import LoginModal from './Components/modals/login-modal';
import PasswordModal from './Components/modals/password-modal';
import ReinitPasswordModal from './Components/modals/reinit-password-modal';
import OfferBasketModal from './Components/modals/offer-basket-modal';
import PageViewsModal from './Components/modals/page-views-modal';
import chatBoxManager from './Utils/chatBoxManager';
import BtnScrollToTop from './Components/btnScrollTop';


const App = () => {
    // Global apis

    let dispatch = useDispatch();
    let globalStore = useSelector(state => state.global);

    // API dynamiques ou via des JSON
    const [dataGlobalAPI, dataGlobalIsLoaded] = useApi({
        name: 'api/global.json',
        method: 'get'
    });

    let [dataRoutesAPI, routesIsLoaded] = useApi({ name: 'api/router' });
    let { isLoaded } = globalStore;

    let page = useSelector(state => state.page).page;
    let { mobile, desktopOnly, desktop } = useSelector(state => state.breakpoint);

    // Get url params
    const urlParams = new URLSearchParams(window.location.search);

    const searchResultsPath = useSelector(state => state.routes).routes.searchResults;

    // INIT SCROLL LOCK REDUCER
    useEffect(() => {
        dispatch(updateGlobalScrollLock(false));
    }, []); // eslint-disable-line

    // STORE ADD GLOBAL API
    useEffect(() => {
        if (dataGlobalIsLoaded && Object.keys(dataGlobalAPI).length > 0) {
            dispatch(updateGlobalStoreGlobal(dataGlobalAPI));
            if (!getToken() || getToken() === '') {
                setToken(dataGlobalAPI.oauth.default_access_token);
            }
            // console.log('dataGlobalAPI ', dataGlobalAPI);
        }
    }, [dataGlobalIsLoaded, dataGlobalAPI]); // eslint-disable-line


    // STORE ADD ROUTES API
    /*let {routes} = useSelector(state => state.routes);
    useEffect(() => {
        console.log('routes', routes);
    }, [routes]); // eslint-disable-line*/
    useEffect(() => {
        if (routesIsLoaded && Object.keys(dataRoutesAPI).length > 0) {
            dispatch(updateGlobalStoreRoutes(dataRoutesAPI));
            // console.log('dataRouterAPI ', dataRoutesAPI);

            const objectMap = (obj, fn) =>
                Object.fromEntries(
                    Object.entries(obj).map(
                        ([k, v], i) => [k, fn(v, k, i)]
                    )
                );

            let routesStore = {};

            objectMap(dataRoutesAPI, route => {
                switch (route.component) {
                    case 'entity.canonical.node.special.cart_cashback':
                        routesStore.cartCashback = route.path;
                        break;
                    case 'entity.canonical.node.special.cart_webcoupon':
                        routesStore.cartWebcoupon = route.path;
                        break;
                    case 'entity.canonical.node.special.homepage':
                        routesStore.home = route.path;
                        break;
                    case 'entity.canonical.node.special.account_home':
                        routesStore.account = route.path;
                        break;
                    case 'entity.canonical.node.special.account_notifications':
                        routesStore.accountNotifications = route.path;
                        break;
                    case 'entity.canonical.node.special.account_economy':
                        routesStore.accountEconomy = route.path;
                        break;
                    case 'entity.canonical.node.special.account_view':
                        routesStore.accountInformations = route.path;
                        break;
                    case 'entity.canonical.node.special.account_favorites':
                        routesStore.accountFavorites = route.path;
                        break;
                    case 'entity.canonical.node.special.account_community':
                        routesStore.accountCommunity = route.path;
                        break;
                    case 'entity.canonical.node.special.account_edit':
                        routesStore.accountEdit = route.path;
                        break;
                    case 'entity.canonical.node.special.contact_home':
                        routesStore.contact = route.path;
                        break;
                    case 'entity.canonical.node.special.contact_phone_call':
                        routesStore.contactPhoneCall = route.path;
                        break;
                    case 'entity.canonical.node.special.contact_email':
                        routesStore.contactEmail = route.path;
                        break;
                    case 'entity.canonical.node.special.contact_doser':
                        routesStore.contactDoseur = route.path;
                        break;
                    case 'entity.canonical.node.special.search_results':
                        routesStore.searchResults = route.path;
                        break;
                    case 'entity.canonical.node.special.register':
                        routesStore.register = route.path;
                        break;
                    case 'entity.operation.canonical':
                        routesStore.operation = route.path;
                        break;
                    case 'entity.canonical.node.special.economy_cashback':
                        if (typeof routesStore.economyCashback === 'undefined') {
                            routesStore.economyCashback = route.path;
                        }
                        break;
                    case 'entity.canonical.node.special.economy_webcoupon':
                        if (typeof routesStore.economyWebcoupon === 'undefined') {
                            routesStore.economyWebcoupon = route.path;
                        }
                        break;
                    case 'entity.canonical.node.special.brands_landing':
                        routesStore.brandHome = route.path;
                        break;
                    case 'entity.canonical.node.special.community_tips':
                        routesStore.tipsHome = route.path;
                        break;
                    case 'entity.canonical.node.special.community_questions':
                        routesStore.questionsHome = route.path;
                        break;
                    case 'entity.canonical.node.special.community_post_tip':
                        routesStore.postTip = route.path;
                        break;
                    case 'entity.canonical.node.special.community_post_question':
                        routesStore.postQuestion = route.path;
                        break;
                    case 'entity.canonical.node.special.community_search_question':
                        routesStore.searchQuestion = route.path;
                        break;
                    case 'entity.canonical.node.special.community_search_tip':
                        routesStore.searchTip = route.path;
                        break;
                    default:
                        break;
                }
            });

            dispatch(updateRoutesUpdate(routesStore));

        }
    }, [dataRoutesAPI, routesIsLoaded]); // eslint-disable-line

    // STORE SET IS LOADED
    useEffect(() => {
        if (routesIsLoaded && dataGlobalIsLoaded) {
            dispatch(updateGlobalisLoaded(routesIsLoaded && dataGlobalIsLoaded));
        }
    }, [routesIsLoaded, dataGlobalIsLoaded]); // eslint-disable-line


    let { uuid } = useSelector(state => state.user);
    // USER
    const [getUserFavoritesParams, setGetUserFavoritesParams] = useState({});
    const [getUserFavorites, isLoadedGetUserFavorites] = useApiGlobal(getUserFavoritesParams);
    useEffect(() => {
        if (uuid) {
            setGetUserFavoritesParams({
                name: `api/users/favorites`,
                format: 'json',
                method: 'get',
            });
        } else {
            setGetUserFavoritesParams({});
            dispatch(updateUserFavorites(null));
        }
    }, [uuid]); // eslint-disable-line

    useEffect(() => {
        if (getUserFavorites && isLoadedGetUserFavorites) {
            let { status, data } = getUserFavorites;
            //console.log(getUserFavorites);
            if (status === 200) {
                if (data && data.length > 0) {
                    dispatch(updateUserFavorites(data));
                } else {
                    dispatch(updateUserFavorites(null));
                }
            } else {
                //Error
                console.log('error - get fav');
            }
        }
    }, [getUserFavorites, isLoadedGetUserFavorites]); // eslint-disable-line


    // Refresh cart when we click on cart icon
    const [needRefreshCart, setNeedRefreshCart] = useState(0)
    const { cartStepsReset } = useSelector(state => state.global);

    useEffect(() => {
        if (cartStepsReset) setNeedRefreshCart(needRefreshCart + 1);
    }, [cartStepsReset])

    // CART
    const [getCartCashbackParams, setGetCartCashbackParams] = useState({});
    const [getCartWebcouponParams, setGetCartWebcouponParams] = useState({});
    const [getCartCashback, isLoadedGetCartCashback] = useApiGlobal(getCartCashbackParams, [needRefreshCart]);
    const [getCartWebcoupon, isLoadedGetCartWebcoupon] = useApiGlobal(getCartWebcouponParams, [needRefreshCart]);

    useEffect(() => {
        if (uuid) {
            setGetCartCashbackParams({
                name: `api/lba-cashback/basket`,
                method: 'get',
            });
            setGetCartWebcouponParams({
                name: `api/lba-economy/basket`,
                method: 'get'
            });
        } else {
            setGetCartCashbackParams({});
            setGetCartWebcouponParams({});
            dispatch(updateCartCashback([]));
            dispatch(updateCartWebcoupon([]));
        }
    }, [uuid]); // eslint-disable-line

    // Cart Cashback
    useEffect(() => {
        if (getCartCashback && isLoadedGetCartCashback) {
            let { status, data } = getCartCashback;
            if (status === 200) {
                // remove items with name === null
                if (data && Array.isArray(data)) {
                    const newData = data.filter(offer => offer.name && offer.name !== null);
                    dispatch(updateCartCashback(newData));
                    dispatch(updateCartCashbackError(null));
                } else {
                    dispatch(updateCartCashback([]));
                    dispatch(updateCartCashbackError(null));
                }
            } else {
                //Erreur
                console.log('error - get cashback cart', data.error_code);

                if (data && data.message) {
                    dispatch(updateCartCashbackError(data.error_code ? data.error_code : 'Une erreur est survenue : ' + data.message));
                }
                else {
                    dispatch(updateCartCashbackError(data.error_code ? data.error_code : 'Une erreur est survenue'));
                }
            }
        }
    }, [getCartCashback, isLoadedGetCartCashback]); // eslint-disable-line
    // Cart Webcoupon
    useEffect(() => {
        if (getCartWebcoupon && isLoadedGetCartWebcoupon) {
            let { status, data } = getCartWebcoupon;
            if (status === 200) {
                // remove items with name === null
                if (data && Array.isArray(data)) {
                    const newData = data.filter(offer => offer.name && offer.name !== null);
                    //console.log('Products webcoupon', newData);
                    dispatch(updateCartWebcoupon(newData));
                    dispatch(updateCartWebcouponError(null));
                } else {
                    dispatch(updateCartWebcoupon([]));
                    dispatch(updateCartWebcouponError(null));
                }
            } else {
                //Erreur
                console.log('error - get webcoupon cart');

                if (data && data.message) {
                    dispatch(updateCartWebcouponError('Une erreur est survenue : ' + data.message));
                }
                else {
                    dispatch(updateCartWebcouponError('Une erreur est survenue'));
                }
            }
        }
    }, [getCartWebcoupon, isLoadedGetCartWebcoupon]); // eslint-disable-line

    // DISPATCH WINDOW SIZE ON RESIZE
    useEffect(() => {
        const checkDevice = () => {
            if (window.matchMedia('(max-width:1023px)').matches && !mobile) {
                dispatch(updateIsMobile(true));
                dispatch(updateIsDesktopOnly(false));
                dispatch(updateIsDesktop(false))
            }
            if (window.matchMedia('(min-width:1024px)').matches && !desktop) {
                dispatch(updateIsMobile(false));
                dispatch(updateIsDesktopOnly(false));
                dispatch(updateIsDesktop(true))
            }
            if (window.matchMedia('(min-width:1025px)').matches && !desktopOnly) {
                dispatch(updateIsMobile(false));
                dispatch(updateIsDesktopOnly(true));
                dispatch(updateIsDesktop(true))
            }
        };

        checkDevice();

        window.addEventListener('resize', checkDevice);

        // clean listener
        return () => {
            window.removeEventListener('resize', checkDevice);
        }
    }, [desktop, desktopOnly, mobile]); // eslint-disable-line


    useEffect(() => {
        if (dataGlobalIsLoaded) {
            // INIT YOUTUBE REDUCER
            dispatch(updateGlobalYoutube(false));

            let timer = 6000;
            let timerJours = 2592000 // 30jours
            let srcid_auth_header_key = dataGlobalAPI?.srcid_auth_header_key;
            if(dataGlobalAPI?.oauth?.access_token_expiration){
                timer = dataGlobalAPI.oauth.access_token_expiration;
            }
            // TRACKING CAMPAGNE MEDIAS - Set cookie "srcid" (check url params)
            if (urlParams.get('srcid') && getCookie('srcid') !== urlParams.get('srcid') && srcid_auth_header_key) {
                setCookieSeconds("srcid", urlParams.get('srcid'), timerJours);
                setCookieSeconds("srcid_auth_header_key", srcid_auth_header_key, timer);
            } 
            if (urlParams.get('gclid')) {
                setCookieSeconds("srcid", 51, timerJours);
                setCookieSeconds("srcid_auth_header_key", srcid_auth_header_key, timer);
            }
            
            let urllp = getCookie('urllp');
            if(!urllp || urllp == ""){
                setCookieSeconds("urllp", document.location.href, timerJours);
            }
        }
    }, [dataGlobalIsLoaded, dataGlobalAPI ]); // eslint-disable-line


    return (
        <div className={`app ${page}`}>

            {/* <LoaderPage load={isLoaded}/> */}

            <Router history={history}>

                {isLoaded ? (
                    <ScrollToTop>
                        {/* <User /> */}
                        <Header />

                        <Breadcrumb />
                        <div className="c-search">
                            <div className="container">
                                <Search
                                    searchResultsPath={searchResultsPath}
                                    placeholder={"Rechercher une réduction, un article"} />
                            </div>
                        </div>

                        <Switch>
                            {
                                chatBoxManager.reset()
                            }
                            {dataRoutesAPI && (
                                dataRoutesAPI.map((route) => {
                                    return <RouteForTemplates key={route.uid} {...route} datas={{}} />;
                                })
                            )}
                        </Switch>

                        <BtnScrollToTop />
                        <Footer />

                        <LoginModal />
                        <PasswordModal />
                        <ReinitPasswordModal />
                        <OfferBasketModal />
                        <PageViewsModal />

                    </ScrollToTop>

                ) : <div style={{ height: '100vh' }}></div>
                }

            </Router>

        </div>
    );
};

export default App;
