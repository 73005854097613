export const getMsgError = (code) => {
  console.log(code)
  let msg="Une erreur est survenue : "+code;
  let msgArray = {
      "SOGEC_PARTICIP_CREATE_MISSING_PARAMETER" : "Une erreur est survenue : il manque une information pour procéder à votre demande de remboursement. Nous vous conseillons de vérifier que vous avez bien transmis toutes les informations demandées.", 
      "SOGEC_PARTICIP_MAIL_TOO_LONG" : "Une erreur est survenue : votre adresse email dépasse le nombre de caractères autorisés (50 caractères)",
      "SOGEC_PARTICIP_RECEIPT_BAD_FILE_TYPE" : "Une erreur est survenue : le format de votre pièce jointe n’est pas autorisé. Seuls les formats de fichier .jpg et .png sont autorisés.",
      "SOGEC_PARTICIP_INVALID_BARCODE" : "Une erreur est survenue : le produit sélectionné ne correspond pas aux informations mentionnées sur votre ticket de caisse.",
      "SOGEC_PARTICIP_PRODUCT_OR_OFFER_NOT_FOUND" : "le produit sélectionné ne bénéficie d’aucune offre de réduction en ce moment.",
      "SOGEC_PARTICIP_PAYMENT_TYPE_MISSING" : "Une erreur est survenue : il manque votre preuve d’achat. Votre preuve d’achat doit nous être transmise pour pouvoir bénéficier de cette offre de réduction.",
      "SOGEC_PARTICIP_PAYMENT_TYPE_INVALID" : "Une erreur est survenue : le type de paiement renseigné est invalide.",
      "SOGEC_PARTICIP_PAYMENT_EMAIL_INVALID" : "Une erreur est survenue : l’email transmis pour procéder au remboursement est invalide.",
      "SOGEC_PARTICIP_PAYMENT_BANK_INFO_MISSING" : "Une erreur est survenue : l’IBAN ou le BIC n’a pas été indiqué.",
      "SOGEC_PARTICIP_PAYMENT_IBAN_INVALID" : "Une erreur est survenue : l’IBAN fourni n’est pas valide.",
      "SOGEC_PARTICIP_PAYMENT_BIC_INVALID" : "Une erreur est survenue : le BIC fourni n’est pas valide.",
      "SOGEC_PARTICIP_SUBMIT_ERROR" : "Une erreur est survenue : le système de traitement de votre demande a détecté une erreur. Veuillez renouveler votre demande en prenant soin de bien compléter chaque case SVP.",
      "SOGEC_BASKET_NO_OFFERREF" : "Une erreur est survenue : nous ne trouvons pas la référence de cette offre. Nous vous conseillons de renouveler votre demande en prenant soin de bien indiquer chaque information demandée.",
      "SOGEC_BASKET_ALREADY_IN_BASKET" : " Une erreur est survenue : l’offre de réduction sélectionnée est déjà dans votre panier. Vous ne pouvez pas sélectionner 2 fois la même offre de réduction.",
      "SOGEC_BASKET_NO_OFFER" : "Une erreur est survenue : le produit sélectionné ne bénéficie d’aucune offre de réduction.",
      "SOGEC_MAX_SUBMISSION_OR_EXPIRED" : "Une erreur est survenue : soit vous avez atteint le nombre maximum d’utilisation de cette offre de réduction, soit la date de validité de l’offre est dépassée.",
      "ACCESS_DENIED" : "Une erreur est survenue : accès refusé.",
      "MISSING_REQUEST_BODY":"Aucun paramètre n'a été reçu pour traiter la demande", 
      "MEMBER_AUTHENTICATION_ERROR": "Il a été tenté d'accéder à la ressource avec un compte qui n'est pas celui d'un membre valide (pas d'ID Web@Henkel).",
      "MISSING_FILTER_VALUE": "Une ressource nécessitant une valeur de filtrage a été appelée sans cette valeur.",
      "INVALID_FILTER_VALUE": "Une ressource nécessitant une valeur de filtrage a été appelée avec une valeur invalide.",
      "MISSING_FILTER_KEY": "Une ressource nécessitant une clé de filtrage a été appelée sans cette valeur (par exemple sans clé \"brand\" pour la ressources de coupons filtrés par marque.",
      "INVALID_FILTER_KEY": "Une ressource nécessitant une clé de filtrage a été appelée avec une valeur invalide (par exemple avec une clé autre que \"universe\", \"shelf\" ou \"brand\" pour la ressources de coupons filtrés.",
      "START_DATE_PARAM_MISSING": "Une ressource nécessitant une date de début a été appelée sans cette valeur.",
      "END_DATE_PARAM_MISSING": "Une ressource nécessitant une date de fin a été appelée sans cette valeur.",
      "MISSING_RECEIPT": "Le reçu n'a pas été fourni.",
      "HTTP_REQUEST_ERROR": "Une erreur de requête non-spécifique.",
      "OAUTH_INVALID_TOKEN": "Le token d'authentification fourni n'est pas/plus valide.",
      "PASSWORD_RECOVERY_BAD_TOKEN": "Mot de passe perdu, mauvais token.",
      "INVALID_ENTITY_FIELD_VALUE": "Erreur de valeur sur le champ d'une entité (un Utilisateur/Membre probablement), par exemple lors de l'inscription : le login ou l'e-mail demandé est déjà utilisé.",
      "ENTITY_ERROR": "Erreur d'ordre plus générale sur une entité (un Utilisateur/Membre probablement)",
      "INVALID_CASHBACK_PAYMENT_TYPE": "Le type de remboursement est invalide",
      "IBAN_NOT_AVAILABLE": "Votre IBAN doit être valide",
      "IBAN_PARAMETER_MISSING": "Le paramètre Iban est manquant.",
      "REGISTRATION_CODE_INVALID_PARAMETER" : "Ce code n'est pas valide",
      "REGISTRATION_CODE_NOT_FOUND" : "Ce code n'est pas valide",
      "REGISTRATION_CODE_ALREADY_USED" : "Ce code a déjà été utilisé"
  }

  // msgArray.forEach(function(value, key) {
  //     console.log(value, key);
  //     if(code.indexOf(key)) {
  //         msg=value
  //     }
  // })
  // console.log(msgArray[code])
  if(msgArray[code]) {
      msg=msgArray[code];
  }
  
  return msg;
};
